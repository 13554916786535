import Vue from 'vue';

export interface ILcpOptimizationMixin {
	isNewVisitor: boolean;
	nuxtReady: boolean;
	userInteracted: boolean;
	previewImageOptimization: boolean;
	isMobileBreakpointRange: boolean;
	lcp: {
		optimizationDesktop: boolean;
	};

	deferOnNuxtReady(callback: Function, allowSSR: boolean | undefined): void;

	deferOnFirstUserInteraction(callback: Function, allowSSR?: boolean | undefined): void;
}

export const LcpOptimizationMixin = Vue.extend({
	data() {
		return {
			isNewVisitor: true,
			previewImageOptimization: true,
			userInteracted: !!this.$store?.state?.ux?.userInteracted,
			nuxtReady: !!this.$store?.state?.ux?.nuxtReady,
			lcp: {
				optimizationDesktop: false,
				watchers: {} as {
					[key: string]: any;
				},
			},
		};
	},
	created() {
		this._updateStatusFlags();
		this._updatePreviewImageOptimization();
	},
	beforeMount() {
		if (!this.userInteracted) {
			this._setOneTimeStoreWatcher('userInteracted', () => {
				this._updateStatusFlags();
				this._updatePreviewImageOptimization();
			});
		}

		if (!this.nuxtReady) {
			this._setOneTimeStoreWatcher('nuxtReady', () => {
				this._updateStatusFlags();
				this._updatePreviewImageOptimization();
			});
		}
	},
	beforeDestroy() {
		Object.values(this.lcp.watchers).forEach((unwatch: null | Function) => unwatch?.());
	},
	methods: {
		_updateStatusFlags() {
			this.isNewVisitor = this.isNewVisitorCheck();
			this.userInteracted =
				this.$store?.state?.ux?.userInteracted || (process.client && window?.scrollY > 0);
			this.nuxtReady = this.$store?.state?.ux?.nuxtReady;
		},
		isNewVisitorCheck() {
			return (
				process.server ||
				(this.$nuxt
					? !this.$nuxt?.context?.from || 'force-device' in this.$nuxt?.context?.from?.query
					: !document.referrer || !document.referrer?.includes(window?.location?.hostname))
			);
		},
		deferOnNuxtReady(callback: Function, allowSSR = false): void {
			if ((allowSSR && process?.server) || this.nuxtReady) {
				callback();
			} else if (this.$store?.state?.ux?.nuxtReady) {
				callback();
			} else {
				(window as any).onNuxtReady(() => callback());
			}
		},
		deferOnFirstUserInteraction(callback: Function, allowSSR = false): void {
			if ((allowSSR && process?.server) || this.userInteracted) {
				callback();
			} else if (this.$store?.state?.ux?.userInteracted) {
				callback();
			} else {
				this.$root.$on('firstUserInteraction', callback);
			}
		},
		_setOneTimeStoreWatcher(propertyName: string, callback: Function): void {
			if (!this.$store) return;

			const watcherName = `${propertyName}Watcher`;

			this.lcp.watchers[watcherName] = this.$watch(
				`$store.state.ux.${propertyName}`,
				(newValue: boolean) => {
					if (!newValue) return;

					// @ts-ignore
					this[propertyName] = true;

					callback();
					this.lcp.watchers[watcherName]?.();
				},
			);
		},
		_updatePreviewImageOptimization(): void {
			this.previewImageOptimization =
				process.server ||
				((this.BREAKPOINT.IS_MOBILE ||
					(this.lcp.optimizationDesktop && this.BREAKPOINT.IS_DESKTOP)) &&
					this.isNewVisitor &&
					!this.userInteracted);
		},
	},
});
