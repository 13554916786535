import Vue, { VNode } from 'vue';
import _isEmpty from 'lodash-es/isEmpty';
import _isNil from 'lodash-es/isNil';
import _size from 'lodash-es/size';
import { debounce } from '@/node_modules/@osp/design-system/assets/js/utilities/debounce';
import Logger from '@/node_modules/@osp/utils/src/logger';
import { IClsOptimizationMixin } from '@/node_modules/@osp/design-system/components/mixins/cls-optimization-mixin';
import { Fragment } from '@/node_modules/@osp/design-system/components/FragmentComponent/FragmentComponent';
import { Bool, Component, Mixins, Prop } from '@/app-utils/decorators';
import { useServerContextStore } from '~/@api/store/serverContextApi';
import { useServerSettingsStore } from '~/@api/store/serverSettingsApi';
import { IntersectionComponentConfig } from '~/@api/store.types';
import { ClsOptimizationMixin } from '~/components/mixins/cls-optimization-mixin';

@Component({
	mixins: [ClsOptimizationMixin],
	data() {
		return {
			clsPreparedComponents: {},
			clsUID: false,
		};
	},
})
export default class IntersectingComponent extends Mixins<Vue & IClsOptimizationMixin>(Vue) {
	private renderContent = false;
	private observer: IntersectionObserver = null;
	private isIntersecting = false;
	private timeoutElement = null;

	@Prop()
	public preload: string;

	@Prop()
	public threshold: number;

	@Prop()
	public timeout: number;

	@Prop()
	public configId: string;

	@Prop({ default: false })
	public horizontal: boolean;

	@Bool()
	public disabled: boolean;

	@Prop()
	public clsComponentKeys: string[];

	get isDisabled() {
		return this.config.disabled || this.disabled;
	}

	get options(): IntersectionObserverInit {
		return {
			rootMargin: `${this.preload || this.config.rootMargin || '15%'}`,
			threshold: this.threshold || this.config.threshold || 0,
		};
	}

	get config(): IntersectionComponentConfig {
		if (this.isIntersecting) {
			return {};
		}

		let config = null;
		let component = null;

		if (this.configId) {
			config = this.configId;
		} else if (_isEmpty(this.$slots.default)) {
			config = 'default';
		} else {
			if (_size(this.$slots.default) > 1) {
				Logger.info(
					'More than one child component found for intersecting-component (lazy loading). Using first for determining configuration',
				);
			}

			component = this.getValidComponent(this.$slots.default[0]);
			config = (component.componentOptions || component.asyncMeta).tag;
		}

		const configs = useServerSettingsStore(this.$store).state.settings.intersectingComponents || [];
		const containsClsComponent = !!component?.componentOptions?.listeners?.clsPreparationFinished;
		const componentTag = component ? (component.componentOptions || component.asyncMeta).tag : null;

		return {
			componentTag,
			timeout: this.timeout,
			containsClsComponent,
			...(configs.find((item) => item.id === 'default') || {}),
			...(configs.find((item) => item.id === config) || {}),
		};
	}

	private getValidComponent(component: VNode) {
		if (
			['App', 'Layout', 'LazyHydrate'].includes(
				(component.componentOptions || (component as any).asyncMeta).tag,
			)
		) {
			if (_isEmpty(component.componentOptions.children)) {
				Logger.info('Could not find valid component config for intersection config');
			} else {
				return this.getValidComponent(component.componentOptions.children[0]);
			}
		}

		return component;
	}

	created() {
		this.isIntersecting =
			useServerContextStore(this.$store).state.userAgent.isBot || this.isDisabled;

		if (this.clsComponentKeys) {
			this.clsComponentKeys.forEach((key: string, index: number) => {
				Vue.set(this.clsPreparedComponents, key, index === 0);
			});
		}
	}

	mounted() {
		if (!this.isIntersecting) {
			if (this.isDisabled) {
				this.isIntersecting = true;
			} else if (this.config.onScroll) {
				// eslint-disable-next-line require-await
				Vue.nextTick(async () => {
					this.handleContentDimensions();
				});
			} else {
				this.createObserver();
			}
		}
	}

	destroyed() {
		this.destroyObserver();
	}

	onScroll() {
		document.removeEventListener('scroll', this.getDebouncedOnScroll());
		this.createObserver();
	}

	render(h) {
		let style = { width: '100%', height: this.horizontal ? '100%' : '2000px' };

		if (this.config.dummyStyle) {
			try {
				style = JSON.parse(this.config.dummyStyle);
			} catch (e) {
				Logger.warn('Found invalid default style JSON for intersection component', this.config);
			}
		}

		if (this.isIntersecting || this.isDisabled) {
			this.$emit('rendering');
			return h(Fragment, this.$slots.default);
		}

		return h('div', { style });
	}

	getDebouncedOnScroll() {
		return debounce(this.onScroll, 250);
	}

	handleContentDimensions() {
		const isHigherThanViewport = (element: HTMLElement) => {
			if (element && element.offsetHeight < window.innerHeight) {
				this.isIntersecting = true;
			} else {
				document.addEventListener('scroll', this.getDebouncedOnScroll());
			}
		};
		// Special use case for e.g. cart page to load footer directly
		// First try to load new Vue specific page content (first selector)
		// and if nothing is found, check if we are on a legacy angular page
		let content: HTMLElement = document.querySelector("[class*='osp_master'] > main");

		if (!content) {
			content = document.querySelector("[class='l-content'][data-currency-iso-code]");
		}

		if (content.offsetHeight <= 0) {
			// A hacky way to wait until lazy loaded content inside our selector is ready, so that its height can be calculated
			// Otherwise height will be always 0
			new MutationObserver((_mutations, obs) => {
				if (content.offsetHeight) {
					isHigherThanViewport(content);
					obs.disconnect();
				}
			}).observe(content, {
				childList: true,
				subtree: true,
			});
		} else {
			isHigherThanViewport(content);
		}
	}

	createObserver() {
		if (_isNil(this.observer)) {
			this.observer = new IntersectionObserver((entries) => {
				this.isIntersecting = entries.some((entry) => entry.isIntersecting);

				if (this.isIntersecting) {
					this.destroyObserver();

					if (!_isNil(this.timeoutElement)) {
						clearTimeout(this.timeoutElement);

						this.timeoutElement = null;
					}
				}
			}, this.options);

			this.observer.observe(this.$el);
		}
	}

	destroyObserver() {
		if (this.config.onScroll) {
			document.removeEventListener('scroll', this.getDebouncedOnScroll());
		}

		if (this.observer) {
			this.observer.disconnect();

			this.observer = null;
		}
	}
}
